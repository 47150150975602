import { useState } from "react";
import styled from "styled-components";

import burgerSVG from "./burger.svg";
import { NavLinks } from "./NavBar";

const ClickCapture = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  top: 54px;
  left: 0;
  z-index: 1;
`;
const Burger = styled.div`
  width: 30px;
  height: 24px;
  display: inline-block;
  background-size: cover;
  background-image: url(${burgerSVG});
  cursor: pointer;
`;
const NavMenu = styled.div`
  position: fixed;
  background-color: #fff;
  right: 0;
  width: 250px;
  top: 54px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  transition: right 0.3s;
  a {
    font-size: 1.25em;
    padding: 15px 30px !important;
    margin: 0 !important;
    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

function HamburgerMenu(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Burger onClick={() => setIsOpen(!isOpen)} />
      {isOpen && <ClickCapture onClick={() => setIsOpen(false)} />}
      <NavMenu
        style={{ right: isOpen ? 0 : "-260px" }}
        onClick={() => setIsOpen(false)}
      >
        <NavLinks />
      </NavMenu>
    </>
  );
}

export default HamburgerMenu;
