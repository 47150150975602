import styled from "styled-components";

import icy from "./projects/icy.jpg";
import howmuch from "./projects/howmuch.png";
import cloudy from "./projects/cloudy.png";
import transverse from "./projects/fxhash.png";
import blanket from "./projects/blanket.jpg";

const Hello = styled.h1`
  font-family: "Quicksand";
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 60px;

  @media (max-width: 800px) {
    font-size: 1.3em;
    br {
      display: none;
    }
  }
`;
const Project = styled.div`
  margin-bottom: 40px;

  > div {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: 50%;
  }
  h3 {
    font-family: "Quicksand";
    font-size: 1.25em;
    margin: 0.5em 0 0;
    font-weight: normal;
    display: flex;
    justify-content: space-between;

    b {
      margin-right: 20px;
    }
  }
  p {
    margin: 0.5em 0;
    color: #555;
    text-align: right;
  }

  @media (max-width: 600px) {
    > div {
      height: 200px;
    }
  }
`;

const works = [
  {
    title: "Icy",
    date: 2023,
    image: icy,
    description:
      "photo series of glaciers on a black sand beach, printed on aluminum",
  },
  {
    title: "How much is enough?",
    date: 2023,
    image: howmuch,
    description:
      "a set of lenticular prints, showing the crashing ocean waves of Santa Rosa island",
  },
  {
    title: "cloudyvision",
    date: 2022,
    image: cloudy,
    description:
      "website and pen plotter prints, a modified dithering algorithm running on clouds",
  },
  {
    title: "Transverse Mercator",
    date: 2021,
    image: transverse,
    description:
      "nft series on fxhash — all maps distort, but the question is in which ways?",
  },
  {
    title: "Marfa/Shinjuku",
    date: 2019,
    image: blanket,
    description:
      "street map of marfa and building map of shinjuku, overlaid and woven as a blanket",
  },
];

function Landing() {
  return (
    <>
      <Hello>
        rachel binx is a new media artist <br /> who creates generative forms
        based on natural phenomena and our collective cartographic
        hallucination.
      </Hello>

      {works.map((d, i) => {
        return (
          <Project key={`proj${i}`}>
            <div style={{ backgroundImage: `url(${d.image}` }} />
            <h3>
              <b>{d.title}</b> {d.date}
            </h3>
            <p>{d.description}</p>
          </Project>
        );
      })}

      {/* <ul>
        <li>marfa tumbleweed</li>
        <li>california coastline</li>
      </ul> */}
    </>
  );
}

export default Landing;
