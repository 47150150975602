import styled from "styled-components";
import { Routes, Route } from "react-router-dom";

import NavBar from "./components/ui/NavBar";
import Landing from "./components/Landing";

const Wrapper = styled.div`
  padding: 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;
function App() {
  return (
    <>
      <NavBar />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </Wrapper>
    </>
  );
}

export default App;
